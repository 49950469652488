

.contactBlock{
    margin: 200px 400px;
    box-sizing: border-box;
    display: block;
    font-family: 'TT Norms', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    line-height: 1.5;
}
.container{
    max-width: 960px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;

}
.row{
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    text-align: center;
    font-family: 'TT Norms', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #000;
}
.item{
    margin: 1px 0;
    flex: 0 0 25%;
    max-width: 25%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
.phonePng{
    width: 50px;
    height: 50px;
    margin: 0 auto 20px;
    border-style: none;
    vertical-align: middle;
}
.locationPng{
    width: 50px;
    height: 50px;
    margin: 0 auto 20px;
    border-style: none;
    vertical-align: middle;
}
.mailPng{
    width: 50px;
    height: 50px;
    margin: 0 auto 20px;
    border-style: none;
    vertical-align: middle;
}


.number{
    margin-top: 20px;
    font-family: 'TT Norms', sans-serif;
    color: #000;
    cursor: pointer;
    font-size: 18px;
    line-height: 1.6;
    font-weight: 700;
}
.mail{
    margin-top: 20px;
    font-family: 'TT Norms', sans-serif;
    color: #000;
    cursor: pointer;
    font-size: 18px;
    line-height: 1.6;
    font-weight: 700;
}
.location{
    margin-top: 20px;
    font-family: 'TT Norms', sans-serif;
    color: #000;
    cursor: pointer;
    font-size: 18px;
    line-height: 1.6;
    font-weight: 700;
}


.pageContent{
    margin: 0;
    padding: 60px 0;
}
.title{
    margin-top: 50px;
    font-size: 48px;
    line-height: 1.2;
    font-weight: 500;
    text-align: center;
    margin-bottom: 50px;
    font-family: 'TT Norms', sans-serif;
    color: #000;
}

.rowInputs{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    margin-right: -15px;
    margin-left: -15px;
}
.itemInput{
    display: block;
    box-sizing: border-box;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    position: relative;
    padding-right: 15px;
    padding-left: 50px;
}
.formGroup{
    position: relative;
    margin: 0;
}
.formLabel{
    position: absolute;
    top: 14px;
    left: 15px;
     font-size: 18px;
    line-height: 18px;
    color: #A5A5A5;
    z-index: 1;
}
.styledInput{
    position: relative;
    width: 100%;
    border: none;
    font-size: 18px;
    border-bottom: 1px solid #A5A5A5;
    background: transparent;
    z-index: 2;
    height: 46px;
    padding: 0 15px;
    transition: border-color ease-in-out 0.2s;
    appearance: none;
    outline: none !important;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    line-height: inherit;
    overflow: visible;
}

.formGroupSecond{
    position: relative;
    margin: 0;
}
.formLabelSecond{
    font-weight: 400;
    font-family: 'TT Norms', sans-serif;
    position: absolute;
    top: 14px;
    left: 15px;
    font-size: 18px;
    line-height: 18px;
    color: #A5A5A5;
    z-index: 1;
    transition: top ease-in-out 0.2s, font-size ease-in-out 0.2s;
}
.styledTextarea{
    position: relative;
    width: 100%;
    border: none;
    font-size: 18px;
    display: block;
    padding: 10px 15px;
    min-height: 120px;
    max-width: 100%;
    border-bottom: 1px solid #A5A5A5;
    background: transparent;
    z-index: 2;
    border-bottom: 1px solid #A5A5A5;
    transition: border-color ease-in-out 0.2s;
    appearance: none;
    font-family: 'TT Norms', sans-serif;
    font-weight: 400;
    outline: none !important;
    box-shadow: none;
    resize: vertical;
    line-height: inherit;
    margin: 0;
    overflow: auto;

}
.formBtn{
    text-align: right;
    width: 20%;
    margin-top: 24px;
    outline: none;
    background: #EF6225;
    border: 2px solid #EF6225;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    padding: 10px 25px;
    border-radius: 37px;
}

.formBtn:hover{
    color: #EF6225;
    border: 2px solid #EF6225;
    background-color: white;
}
.formAgreement{
    text-align: left;
    max-width: 290px;
    color: #8F8F8F;
    margin-top: 8px;
    font-size: 12px;
    line-height: 1.5;
}
.formAgreementA{
    color: #6D6D6D;
    text-decoration: underline;
}
