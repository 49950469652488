.escortBlock{
    padding-top: 60px;
    padding-bottom: 30px;
}
.container{
    max-width: 960px;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    border: 0;
    display: table;
    content: " ";
    clear: both;
}

.escortPn{
    width: auto;
    max-width: 460px;
    margin: 0;
    padding: 0;
    border: 0;


}
.wrapperBlockText{
    max-width: 460px;
    display: inline;
    float: left;
    margin-left: 10px;
    margin-right: 10px;
    width: 100%;
    padding: 0;
    border: 0;
}
.title{
    line-height: 1.23;
    font-family: 'Roboto',Arial,sans-serif;
    font-weight: 600;
    color: #000000;
    font-size: 34px;
    margin: 0;
    padding: 0;
    border: 0;

}
.text{
    font-size: 16px;
    margin: 0;
    padding-top: 20px;
    border: 0;
    line-height: 1.55;
    font-family: 'Roboto',Arial,sans-serif;
    font-weight: 300;
    color: #000000;
}
.li{
    position: relative;
    padding-left: 20px;
    margin-bottom: 15px;
    display: list-item;
    text-align: -webkit-match-parent;
    list-style: none;
}
.li::before{
    position: absolute;
    padding-left: 1px;
    margin-bottom: 1px;
    top: 14px;
    left: 0;
    width: 6px;
    height: 6px;
    background: #EF6225;
    content: '';
    border-radius: 100%;
}




