.contentBlock{
    margin-top: 120px;
    display: flex;



}

img{
    margin-left: 300px;


}
