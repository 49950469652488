



.item{
    box-sizing: border-box;
    margin: 20px 0;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    font-family: 'TT Norms', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    text-align: left;
    flex: 0 0 50%;
    max-width: 50%;
}

.titleItem{
    font-size: 30px;
    line-height: 1.2;
    font-weight: 500;
    color: #EF6225;
    margin-bottom: 15px;
    font-family: 'TT Norms', sans-serif;
    text-align: left;
}
.styledText{
    font-size: 18px;
    line-height: 1.9;
    color: #2B2B2B;
    font-weight: 400;
    font-family: 'TT Norms', sans-serif;
    text-align: left;
}
