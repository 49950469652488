.headerBlock{
    padding: 20px;
    position: fixed;
    top: -10px;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 100;
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    align-items: baseline;
}
.titleCompany{
    top:10px;
    color: black;
    font-size: 40px;
    line-height: 1.23;
    font-family: 'Roboto',Arial,sans-serif;
    font-weight: 600;
    text-align: center;
}
.btn{
    margin-bottom: 7px;
    background: #EF6225;
    color: #fff;
    display: inline-block;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    text-align: center;
    border: 2px solid transparent;
    padding: 10px 25px;
    border-radius: 37px;
    cursor: pointer;
}
.btn:hover{
       color: #EF6225;
       border: 2px solid #EF6225;
       background-color: white;
   }
.phone{
    font-size: 20px;
    font-family: 'TT Norms', sans-serif;
    font-weight: 400;
    color: #000;
}
a{
    text-decoration: none;
    outline: none !important;
    border-radius: 0;
    box-shadow: none;
    display: inline-block;
    line-height: 1;
    font-weight: 500;
    color: #000;
    transition: color 0.3s;
    background-color: transparent;
    cursor: pointer;
    font-family: 'TT Norms', sans-serif;
}
span{
    color: #EF6225;
    font-weight: 700;
}

