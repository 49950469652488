.companyBlock{
    margin-top: 380px;
}
.nameMainCompany{
    top:-250px;
    text-align: center;
    position: relative;
    color: #EF6225;
    font-size: 40px;
    line-height: 1.23;
    font-family: 'Roboto',Arial,sans-serif;
    font-weight: 600;
    margin: 20px;
    padding: 0;
    border: 0;
}
.nameCompany{
    top:-270px;
    text-align: center;
    position: relative;
    color: #000000;
    font-size: 40px;
    line-height: 1.23;
    font-family: 'Roboto',Arial,sans-serif;
    font-weight: 600;
    margin: 20px;
    padding: 0;
    border: 0;
}

.titleRow{
    margin: 0;
    padding: 0;
    border: 0;
    text-align: center;
}
.prefix{
    top:-270px;
    position: relative;
    padding-left: 400px;
    margin-left: 10px;
    margin-right: 10px;
    width: 100%;
    display: block;
    float: none;
    text-align: center;

}
.line{
    width: 10%;
    height: 1px;
    background-color: black;
    margin-left: 400px;
}
.textCompany{
    top:-270px;
    position: relative;
    padding-left: 500px;
    max-width: 780px;
    display: block;
    float: none;
    margin-top: 43px;
    margin-bottom: 0;
    font-size: 22px;
    line-height: 1.55;
    font-family: 'Roboto',Arial,sans-serif;
    font-weight: 300;
    color: #000000;
    text-align: center;
}
em{
    font-style: italic;
    font-size: 22px;
    line-height: 1.55;
    font-family: 'Roboto',Arial,sans-serif;
    font-weight: 300;
    text-align: center;
}
.jpgCompany{
    height: 80vh;
    width: 100%;
    position: absolute;
    margin: 0;
    opacity: 0.7;
    top: 100px;

}
.pngNote{
    top:-230px;
    position: relative;
    max-width: 290px;
    margin-left: 90rem;
    display: block;
    visibility: visible;

}
.systemTextNote{
    max-width: 10px;
    top:-210px;
    font-size: 24px;
    line-height: 1.55;
    font-family: 'Roboto',Arial,sans-serif;
    font-weight: 300;
    position: relative;
    margin-top: -300px;
    margin-left: 92.7rem;

}


