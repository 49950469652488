.otherServicesBlock {
    margin: 60px 0;


}

.container {
    max-width: 960px;
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
}


.primaryTitle{
    max-width: 860px;
    margin-right: auto;
    margin-left: auto;
    line-height: 1.2;
    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;
    font-size: 48px;
    color: #000;
    font-family: 'TT Norms', sans-serif;
}

.secondaryTitle{
    font-size: 30px;
    line-height: 1.2;
    font-weight: 500;
    text-align: center;
    margin: -10px 0 40px;
    color: #000;
    font-family: 'TT Norms', sans-serif;
}
.styledText{
    display: flex;
    font-size: 18px;
    line-height: 1.9;
    color: #2B2B2B;
    font-weight: 400;
    font-family: 'TT Norms', sans-serif;

}


.ul{

}

.li{
    position: relative;
    padding-left: 20px;
    margin-bottom: 15px;
    display: list-item;
    text-align: -webkit-match-parent;
    list-style: none;
}



.li::before{
    position: absolute;
    padding-left: 1px;
    margin-bottom: 1px;
    top: 14px;
    left: 0;
    width: 6px;
    height: 6px;
    background: #EF6225;
    content: '';
    border-radius: 100%;
}
.pngService{
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    max-width: 460px;
    max-height: 326px;
}



