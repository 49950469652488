
.certificateBlock {
    background-color: #f2f2f2;
    padding: 100px;
}


.certificateBlock1 {
    display: flex;
    margin-left: -280px;
    padding: 20px;
}

.certificateBlock2 {

}

.pngCertificate {
    width: 600px;
}
.pngCertificate1{
    margin-top: 10rem;
    margin-left: -2rem;
    width: 600px;
}
.pngCertificate2{
    margin-top: 3rem;
    margin-left: 1.5rem;
    width: 600px;
}
.pngCertificate3 {
    margin-top: -30rem;
    margin-left: 70rem;
    width: 600px;
    transform: rotate(90deg);
}

.pngCertificate4 {
    width: 700px;
    margin-top: -1rem;
    margin-left: -7rem;
}
.certificateBlock3{
    padding: 100px;
    display: flex;
}
.pngCertificate5{
    width: 400px;
    margin-top: -1rem;
    margin-left: 5rem;
}
.pngCertificate8{
    width: 600px;
    margin-top: -2rem;
    margin-left: -10rem;
}
.pngCertificate9{
    width: 600px;
    margin-top: -2rem;
    margin-left: 2rem;
}
.pngCertificate10{
    margin-top: -2rem;
    margin-left: -4rem;
    width: 600px;
    transform: rotate(-90deg);
}