.navBlock{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;


}
.nav{
    margin: 10px;
    text-decoration: none;
    font-size: 18px;
}