.part {

}

.container {
    max-width: 960px;
    padding: 20px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border: 0;
    content: " ";
}

.content {
    max-width: 320px;
    margin: 0;
    padding: 0;
    border: 0;

}

.wrapper {
    display: table;
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: content-box;


}

.icon {
    width: 50px;
    height: auto;
    display: table-cell;    
    vertical-align: middle;
    margin: 0;
    padding-right: 10px;
    border: 0;
}

.name {
    display: table-cell;
    vertical-align: middle;
    font-size: 18px;
    line-height: 1.35;
    font-family: 'Roboto', Arial, sans-serif;
    font-weight: 600;
    color: #333333;
    margin: 0;
    padding: 0;
    border: 0;

}

.description {
    margin-top: 12px;
    font-weight: 300;
    color: #000000;
    padding: 0;
    border: 0;
    display: block;
    font-size: 16px;
    line-height: 1.55;

}
