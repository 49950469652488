.formBlock{
    position: absolute;
    margin: 50px;
    width: 370px;
    padding: 24px 30px 35px;
    background: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    display: block;
    font-family: 'TT Norms', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    line-height: 1.5;
    text-align: left;
    border: 2px solid #EF6225;
}
.formTitle{
    margin-bottom: 8px;
    font-weight: 500;
    color: #000;
    font-size: 15px;
    line-height: 1.4;

}
.formInput{
    width: 100%;
    height: 46px;
    margin-top: 10px;
    font-size: 18px;
    border: none;
    border-bottom: 1px solid #6D6D6D;
    font-family: 'TT Norms', sans-serif;
    font-weight: 400;
    appearance: none;
    border-radius: 0;
    outline: none !important;

}
.formBtn{
    width: 100%;
    margin-top: 24px;
    outline: none;
    background: #EF6225;
    border: 2px solid #EF6225;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    text-align: center;
    padding: 10px 25px;
    border-radius: 37px;
}

.formAgreement{
    margin-top: 8px;
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
    color: #6D6D6D;
}
.formAgreementA{
    color: #6D6D6D;
    text-decoration: underline;
}



