.routinesBlock{

}
.wrapperBlock{
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    font-family: 'TT Norms', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    line-height: 1.5;
    text-align: left;
}
.container{
    max-width: 960px;
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    font-family: 'TT Norms', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    text-align: left;
}

.title{
    max-width: 860px;
    margin-right: auto;
    margin-left: auto;
    line-height: 1.2;
    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;
    font-size: 48px;
    color: #000;
    font-family: 'TT Norms', sans-serif;
}
